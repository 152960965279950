/* Colors Neuhaus */
/* Fonts Neuhaus */
html,
body,
.page {
  color: #503223;
  background-color: #fff;
  line-height: 1.3em;
  min-height: 100vh;
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

body {
  font-size: 1rem; }

.page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }

div[role="main"] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }

/* stylelint-disable-next-line */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS */
  .browserModal {
    display: block !important;
    top: inherit; }
    .browserModal .modal-text {
      max-width: 450px; }
  .modal-background {
    display: block;
    z-index: 500;
    top: 0; }
  body {
    overflow: hidden; } }

a.link {
  color: #503223;
  text-decoration: underline;
  font-weight: 700; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.h1,
h1 {
  font-size: calc(1.425rem + 2.1vw); }
  @media (min-width: 1200px) {
    .h1,
    h1 {
      font-size: 3rem; } }

.h2,
h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    .h2,
    h2 {
      font-size: 2rem; } }

h3,
.h3 {
  font-size: 1.25rem; }

h4,
.h4 {
  font-size: 1.125rem; }

h5,
.h5 {
  font-size: 1rem; }

h6,
.h6 {
  font-size: 0.875rem; }

.font-link, .font-caption, .font-title, .product-price .sales, .product-name-link,
.product-name-link a, .font-heading {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em; }

.font-copy {
  font-family: "Plantin", serif;
  text-transform: none;
  letter-spacing: 0.01em; }

a,
a:hover,
a:active,
a:focus {
  color: #503223; }

.product-price {
  margin-bottom: 0.5rem; }
  .product-price .sales {
    color: #cf7c58;
    font-size: 0.875rem;
    font-weight: 400; }
    @media (min-width: 769px) {
      .product-price .sales {
        font-size: 1.5rem; } }

.product-name-link,
.product-name-link a {
  color: #503223;
  font-size: 0.875rem;
  font-weight: 700; }
  @media (min-width: 769px) {
    .product-name-link,
    .product-name-link a {
      font-size: 1.25rem; } }

.product-tile-pd {
  /* override image path because of deeper folder structure for pagedesigner components */ }
  .product-tile-pd .product-labels {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    max-width: 75%;
    z-index: 1; }
    @media (min-width: 992px) {
      .product-tile-pd .product-labels {
        max-width: 60%; } }
    .product-tile-pd .product-labels .product-label {
      font-family: "Flama", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      background-color: #b14350;
      text-transform: uppercase;
      display: inline-block;
      color: white;
      font-size: 0.75rem;
      padding: 5px;
      margin-bottom: 4px; }
      @media (min-width: 769px) {
        .product-tile-pd .product-labels .product-label {
          font-size: 1rem;
          padding: 8px; } }
    .product-tile-pd .product-labels .product-label-new {
      font-family: "Flama", sans-serif;
      text-transform: uppercase;
      letter-spacing: 0.1em;
      background-color: #503223;
      text-transform: uppercase;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      color: white;
      font-size: 0.75rem;
      margin-bottom: 4px;
      padding-left: 5px;
      padding-right: 5px; }
      @media (min-width: 769px) {
        .product-tile-pd .product-labels .product-label-new {
          font-size: 1rem;
          padding: 8px; } }
    .product-tile-pd .product-labels .personalise-label {
      background-color: #fff;
      border: 1px solid #ddd;
      border-color: #cf7c58;
      color: #cf7c58;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      .product-tile-pd .product-labels .personalise-label:hover {
        background-color: #cf7c58;
        color: #fff; }
      .product-tile-pd .product-labels .personalise-label::after {
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../images/icons/arrow-secondary.svg"); }
      .product-tile-pd .product-labels .personalise-label:hover::after {
        display: inline-block;
        content: "";
        width: 10px;
        height: 10px;
        -webkit-transform: rotate(90deg);
                transform: rotate(90deg);
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("../images/icons/arrow-white.svg"); }
  .product-tile-pd .product-labels .personalise-label::after {
    background-image: url("../../../../images/icons/arrow-secondary.svg"); }

.pdp-link a {
  font-family: "Flama", sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  color: #503223;
  font-size: 0.875rem;
  font-weight: 700; }
  @media (min-width: 769px) {
    .pdp-link a {
      font-size: 1.25rem; } }

.pdp-link .productname {
  padding-right: 2rem; }

.quickview {
  padding: 0;
  height: 40px;
  visibility: hidden;
  width: 40px;
  -ms-flex-negative: 0;
      flex-shrink: 0; }

@media (min-width: 1200px) {
  .product-tile-figure:hover .quickview {
    visibility: visible; } }
